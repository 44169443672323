<template>
  <v-row no-gutters class="pa-2">
    <v-text-field
      v-model="content"
      :placeholder="placeholder"
      dense
      filled
      rounded
      hide-details
      @keydown="onKeydown"
      @paste="onPaste"
    />
    <v-btn :disabled="disabledSend" icon @mousedown.prevent @click="eventSend">
      <v-icon>mdi-send</v-icon>
    </v-btn>
  </v-row>
</template>

<script>
export default {
  props: {
    send: {
      type: Function
    }
  },
  data: () => ({
    content: "",
    placeholder: "Start by typing something in...",
    disabledSend: true
  }),
  methods: {
    onKeydown(e) {
      this.checkDisable();
      if (e.keyCode === 13) {
        this.eventSend();
        e.preventDefault();
      }
    },
    checkDisable() {
      this.disabledSend = !(this.content || "").trim("");
    },
    onPaste(event) {
      const plainText = (event.originalEvent || event).clipboardData.getData('text/plain')
      this.disabledSend = !(this.content || plainText).trim("");
    },
    eventSend() {
      if ((this.content || "").trim("")) {
        this.send(this.content);
        this.content = "";
        this.checkDisable();
      }
    }
  }
};
</script>

<style scoped>
.fixed {
  position: fixed;
}
</style>